const t = {
	translation: {
		LOGIN_WITH: 'Login with',
		LOGIN_BUTTON_TEXT: 'Login',
		SIGN_UP_LINK_TEXT: 'Sign up',
		NEW_USER_REGISTRATION_TEXT: 'New User Registration',
		FIRSTNAME: 'First name',
		LASTNAME: 'Last name',
		EMAIL: 'Email',
		REPEAT_EMAIL: 'Repeat email',
		PASSWORD: 'Password',
		REPEAT_PASSWORD: 'Repeat password',
		TERMS_ACCEPT: 'I accept the terms and conditions',
		CREATE_ACCOUNT_BUTTON: 'Create account',
		USER_PROFILE_TITLE: 'User Profile',
		PROFILE_PICTURE_TITLE: 'Profile Picture',
		REPO_MANAGER_REQUEST: 'Request Repo Manager Access',
		REPO_MANAGER_DIALOG_TITLE: 'Request Repo Manager Access',
		USER_INTERFACE_PREFERENCES_TITLE: 'User Interface Preferences',
		UI_LANGUAGE_TITLE: 'UI Language',
		SELECT_UI_LANGUAGE: 'Select UI Language',
		UI_LANGUAGES_DISPLAY: 'How languages are displayed',
		SELECT_UI_LANGUAGE_DISPLAY: 'ISO Language name',
		UI_DATES_DISPLAY: 'How dates are displayed',
		SELECT_UI_DATES_DISPLAY: 'YYYY-MM-DD',
		USER_TARGETED_REPOSITORIES_TITLE: 'My targeted repositories',
		LOGGED_IN_AS: 'Logged in as:',
		SIGN_OUT: 'Sign Out',
		DASHBOARD: 'My Dashboard',
		ACCOUNT_SETTINGS: 'Account Settings',
		NAME: 'Name',
		ACTIVE_TASKS: 'Active Tasks',
		MY_ACTIVE_TASKS: 'My Active Tasks',
		PENDING_REVIEWS: 'Pending Reviews',
		PENDING_UPLOADS: 'Pending Uploads',
		MY_TEAMS: 'My Teams',
		SHARED_TEAMS: 'Shared Teams',
		CREATE_TEAM: 'Create Team',
		EDIT_TEAM: 'Edit Team',
		YOUR_TEAM_NAME: 'Your team name',
		YOUR_TEAM_DESC: 'Your team description',
		CREATE_A_NEW_TEAM: 'Create A New Team',
		VIEW_DETAILS: 'View Details',
		INVITE_MEMBERS_TO_TEAM: 'Invite Members to {{teamName}}',
		INVITE_BY_EMAIL: 'Invite by email',
		SEARCH_MEMBERS_BY_NAME: 'Search members by name',
		SEND_INVITES: 'Send Invites',
		REPOSITORY_TYPE: 'Type',
		REPOSITORY_NAME: 'Name',
		REPO_MANAGER_PERMISSIONS: 'Request permissions',
		REPOSITORY_CONNECTION_VERIFIED: 'Connection',
		SELECT_REPOSITORY_TYPE: 'Select repository type',
		REPOSITORY_ACTIONS: 'Actions',
		REPOSITORY_API_ENDPOINT: 'API Endpoint (no trailing slash)',
		REPOSITORY_CLIENT_SECRET: 'Client Secret',
		ADD_REPOSITORY: 'Add new repository',
		NO_REPOSITORIES_FOUND: 'No repositories were found, use the form below to add a repository.',
		NO_REPOSITORIES_GENERIC:
      'No targeted repositories were found. Please navigate to your account settings to add a new one.',
		TEAM_DASHBOARD: 'Team Dashboard',
		TEAM_MEMBERS: 'Team Members',
		RESOURCES: 'Resources',
		RESOURCE_COLLECTIONS_TITLE: 'Collections',
		RESOURCE_BELONGS_TO: 'Resource Belongs To',
		AVERAGE_FAIR_SCORING: 'Average Fair Scoring',
		TEAM_COLLECTIONS: 'Team Collections',
		CREATE_NEW_COLLECTION: 'Create New Collection',
		CREATE_A_NEW_COLLECTION: 'Create A New Collection',
		COLLECTION_TITLE: 'Title (in English)',
		COLLECTION_DESCRIPTION: 'Description (in English)',
		COLLECTION_ENTER_DESCRIPTION: 'Enter the description of the collection.',
		COLLECTION_TITLE_LANGUAGE: 'Language',
		NO_AVAILABLE_TITLES: 'No titles are available.',
		NO_AVAILABLE_DESCRIPTIONS: 'No descriptions are available.',
		COLLECTION_ENTER_TITLE: 'Enter the title of the collection.',
		COLLECTION_DESCRIPTION_LANGUAGE: 'Language',
		COLLECTION_TITLE_ADD: 'Add',
		COLLECTION_DESCRIPTION_ADD: 'Add',
		INHERIT_COLLECTION_INFORMATION:
      'Inherit collection information to metadata of related resources',
		PUBLISH_COLLECTION_AS_CATALOGUE_OF_RESOURCES: 'Publish collection as catalogue of resources',
		GARDIAN_PID_TITLE: 'Gardian PID',
		DOI_TITLE: 'DOI',
		DOIS: 'DOI(s)',
		GET_DOI: 'Get DOI',
		PUBLISHER_TITLE: 'Publisher',
		EMBARGO_DATE_TITLE: 'Embargo Date',
		GEOSPATIAL_COVERAGE_COLLECTION_RESOURCES:
      'Geospatial coverage calculated from collection resources',
		TEMPORAL_COVERAGE_COLLECTION_RESOURCES:
      'Temporal coverage calculated from collection resources',
		CREATE_COLLECTION_BUTTON: 'Create Collection',
		IN_X_COLLECTIONS: 'in {{count}} collections',
		ISSUE_DATE: 'Issue Date',
		TITLE: 'Title',
		TYPE: 'Type',
		SUBTYPE: 'Subtype',
		COLLECTIONS: 'Collections',
		PII_STATUS: 'PII Status',
		CERTIFIED: 'Certified',
		FAIR_SCORING: 'Fair Scoring',
		SEARCH_FOR_TEAMS: 'Search for teams...',
		SEARCH_FOR_COLLECTIONS: 'Search for collections...',
		SEARCH_FOR_RESOURCES: 'Search for resources...',
		COLLECTION_NAME: 'Name',
		COLLECTION_RESOURCES: 'Resources',
		COLLECTION_FAIR_SCORING: 'Fair Scoring',
		PUBLISHED_RESOURCES: 'Published Resources',
		RESOURCES_UNDER_PREPARATION: 'Resources Under Preparation',
		RESOURCES_UNDER_REVIEW: 'Resources Under Review',
		UNPUBLISHED_RESOURCES: 'Unpublished Resources',
		PUBLISHED: 'Published',
		UNPUBLISHED: 'Unpublished',
		CREATE_NEW_TASK: 'Create new task',
		EDIT_COLLECTION: 'Edit Collection',
		SAVE_COLLECTION_BUTTON: 'Save Changes',
		SAVE_CHANGES: 'Save Changes',
		CHANGE_PASSWORD: 'Change password',
		OLD_PASSWORD: 'Old password',
		CHANGE_PICTURE: 'Change picture',
		NEW_PASSWORD: 'New password',
		REPEAT_NEW_PASSWORD: 'Repeat new password',
		COLLECTION_ENTER_COUNTRY: 'Enter a country.',
		COLLECTION_ADD_COUNTRY: 'Add',
		NO_AVAILABLE_COUNTRIES: 'No countries are available.',
		COLLECTION_COUNTRY: 'Country',
		FROM_DATE: 'From date',
		TO_DATE: 'To date',
		RESOURCE: 'Resource',
		VIEW_COLLECTION_RESOURCES: 'View Resources',
		HIDE_COLLECTION_RESOURCES: 'Hide Resources',
		TEMPORAL_DATE: 'Date',
		DESCRIPTION: 'Description',
		TEMPORAL_DESCRIPTION: 'Description',
		TEMPORAL_ENTER_DESCRIPTION: 'Enter a description for the time period.',
		NEW_RESOURCE: 'New Resource',
		EDIT_RESOURCE: 'Edit Resource',
		UPDATE_RESOURCE: 'Update Resource',
		RESOURCE_TITLE: 'Resource Title',
		RESOURCE_LANGUAGES_TITLE: 'Resource Language(s)',
		RESOURCE_DESCRIPTION: 'Resource Description',
		RESOURCE_GENERAL_INFORMATION: 'General Information',
		RESOURCE_LANGUAGE: 'Resource Language',
		AUTHORS: 'Authors',
		RESOURCE_TYPE: 'Resource Type',
		RESOURCE_SUBTYPE: 'Resource Subtype',
		ORCID: 'ORCiD',
		RESOURCE_METADATA_AUTHORING_TEAM: 'Metadata Authoring Team',
		RESOURCE_METADATA_REVIEW_TEAM: 'Metadata Review Team',
		CREATE_TASK_AND_SEND_INVITES: 'Create Task & Send Invites',
		CLEAR_ALL_FIELDS: 'Clear all fields',
		PUBLISH_RESOURCE: 'Publish Resource',
		SELECT_TARGET_REPOSITORY: 'Select target repository',
		CHECK: 'Check',
		CHECK_FAIR: 'Check FAIR',
		METADATA_PROPERTY_CONDITION: 'Metadata Property Condition',
		SCORING_IN_FAIR: 'Scoring in FAIR',
		RESOURCE_MEETS_CONDITION: 'Resource meets the condition',
		RECOMMENDATION: 'Recommendation',
		FINDABLE: 'Findable',
		ACCESSIBLE: 'Accessible',
		INTEROPERABLE: 'Interoperable',
		REUSABLE: 'Reusable',
		RESOURCE_METADATA_RECORD: 'Resource Metadata Record',
		RESOURCE_FILES: 'Resource Files',
		UPLOAD_THUMBNAIL: 'Upload Thumbnail',
		NO_ENTRIES_FOUND: 'No entries were found.',
		PHYSICAL_FILES:
      'Physical File(s) relevant to metadata (i.e., dataset, publication, protocol, image, etc.)',
		FILE_NAME: 'File Name',
		UPLOAD_FILES: 'Upload File(s)',
		PUBLISHING_INFORMATION: 'Publishing Information',
		VERIFIED: 'Verified',
		ADD: 'Add',
		SELECT_LANGUAGE: 'Select a language',
		INVITED_TEXT: 'You were invited to join the team',
		BY: 'by',
		ACCEPT_INVITE: 'Accept invite',
		REJECT_INVITE: 'Reject invite',
		AUTHORING_TASK_OWNER_WARNING: 'The task owner is by default in the metadata authoring team.',
		REVIEW_TEAM_OWNER_WARNING: 'The team owner is by default in the metadata review team.',
		CANCEL: 'Cancel',
		AUTHOR_TYPE: 'Type',
		AUTHOR_NAME: 'Name',
		AUTHOR_ID: 'ID',
		PROJECTS: 'Projects',
		LEADER: 'Leader',
		ACTIONS: 'Actions',
		FUNDING_ORGS: 'Funding Organisations',
		ID: 'ID',
		CONTACT_POINTS: 'Contact Points',
		CITATION: 'Citation',
		RESOURCE_LIFECYCLE: 'Lifecycle',
		RESOURCE_VERSION: 'Resource Version',
		VERSION_DESCRIPTION: 'Version Description',
		RELEASE_DATE: 'Release Date',
		EMBARGO_DATE: 'Embargo Date',
		RESOURCE_CLASSIFICATION: 'Classification',
		KEYWORDS: 'Keywords',
		KEYWORD: 'Keyword',
		RESOURCE_RIGHTS: 'Rights',
		RESOURCE_LICENSE: 'License',
		RESOURCE_ACCESS_RIGHTS: 'Access Rights',
		TERMS_OF_USE: 'Terms of use',
		RIGHTS_HOLDERS: 'Rights Holders',
		RELATED_RESOURCES: 'Related Resources',
		PENDING: 'Pending',
		LOCKED: 'Accessible only to permitted users',
		PUBLISHABLE: 'Publishable',
		GEOSPATIAL_COVERAGE: 'Geospatial Coverage',
		LICENSE_WIZARD: 'License Wizard',
		COMMENTS: 'Comments',
		SEND_FOR_REVIEW: 'Send for review',
		REVIEW_COMMENTS: 'Review Comments',
		DELETE_TEAM: 'Delete team',
		DELETE_COLLECTION: 'Delete collection',
		DELETE_RESOURCE: 'Delete resource',
		LANGUAGE: 'Language',
		TEXT: 'Text',
		DATA_COLLECTION_METHODOLOGY: 'Data Collection Methodology',
		UNITS_OF_ANALYSIS: 'Units of Analysis',
		COLLECTION_FREQUENCY: 'Data Collection Frequency',
		POPULATION_COVERED: 'Population Covered by the Data',
		COLLECTION_METHOD: 'Data Collection Method',
		COLLECTION_INSTRUMENT: 'Data Collection Instrument',
		SAMPLING_PROCEDURE: 'Sampling Procedure',
		DOI_SERVICE_PROVIDER: 'DOI Service Provider',
		PIDS: 'Alternative Identifiers',
		PID_VALUE: 'Value',
		PID_SCHEME: 'Scheme',
		TEMPORAL_COVERAGE: 'Temporal Coverage',
		TIME_PERIOD_TO_WHICH_THE_DATA_REFER: 'Time period to which the data refer',
		COLLECTION_PERIOD: 'Data collection period',
		OTHER_RESOURCE_INFORMATION: 'Other Resource Information',
		DISCLAIMER: 'Disclaimer',
		FULLNAME: 'Full name',
		SHORTNAME: 'Short name',
		URL: 'URL',
		SCHEME: 'Scheme',
		CRPS_PLATFORMS_INITIATIVES: 'CRPs / Platforms / Initiatives',
		SELECT_REGIONS_COUNTRIES: 'Select regions and countries',
		BACK: 'Back',
		SEND_FOR_REEDITING: 'Send for re-editing',
		APPROVE: 'Approve',
		VIEW_REPORT: 'View report',
		PII_REPORT: 'PII Report',
		PERSONS: 'Persons',
		GEOCOORDINATES: 'Geocoordinates',
		LEMMA: 'Lemma',
		CLASS: 'Class',
		DATA_ANNOTATION: 'Data Annotation',
		ANNOTATE_DATASET: 'Annotate Dataset',
		DATAVERSE_COLLECTIONS: 'Dataverse Collections',
		SELECT_DATAVERSE_COLLECTION: 'Select Dataverse Collection',
		DATAVERSE_COLLECTIONS_HELP_TEXT: 'Specify the dataverse collections to which you will be able to publish datasets. By default, there\'s a root collection.',
		DATAVERSE_COLLECTIONS_ALREADY_PUBLISHED:
      'You can\'t choose a different collection since you\'ve already published this resource on this repository.',
	},
};

export default t;
